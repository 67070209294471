export const environment = {
  production: true,
  agGridLicence:
    // eslint-disable-next-line max-len
    'Using_this_{AG_Grid}_Enterprise_key_{AG-063101}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Morningstar_Indexes_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{indexengine}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{indexengine}_need_to_be_licensed___{indexengine}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{9_September_2025}____[v3]_[01]_MTc1NzM3MjQwMDAwMA==62226e008bea18efd5fe671f2b9c9fad',
  apiUrl: 'https://api.indexengine.mif0286.eas.morningstar.com/v1',
  eventSurveillanceUrl: 'https://mist.morningstar.com/event-surveillance/ui/?#/tracked-event/',
  streamOptions: {
    url: 'https://api.indexengine.mif0286.eas.morningstar.com/',
    options: {
      path: '/v1/stream',
      reconnectionDelay: 10000,
      reconnectionDelayMax: 60000,
      reconnectionAttempts: Infinity,
      timeout: 60000,
    },
  },
  auth: {
    domain: 'f19kl.eu.auth0.com',
    clientID: 'Z3gTDRWyu8hqaYUeSLRVJJhiDnqDtPCh',
    loginRedirect: '/login',
    options: {
      autoclose: false,
      language: 'en',
      container: 'loginContainer',
      auth: {
        audience: 'moorgate-service',
        responseType: 'token id_token',
        redirectUrl: `${window.location.origin}/login`,
      },
      theme: {
        primaryColor: '#0077cf',
        foregroundColor: '#ffffff',
        logo: 'assets/logo.svg',
      },
      languageDictionary: {
        title: '',
      },
    },
  },
  updateInterval: 1,
};
